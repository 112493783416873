@use '../../AppStyles';

.navbar-container {
    display: flex;
    font-size: 1.2em;
    top: 0;
    align-items: center;
    justify-content: center;
    position: sticky;
    height: 70px;
    background-color: white;
    color: black;
    border-radius: 0 0 20px 20px ;
    width: 100%;
    z-index: 2;
    transition: all 0.7s;
    box-shadow: 0px 0px 1.5px 1.5px rgb(140, 140, 140);
}

.navbar-container.collapsed {
    height: 30px;
    width: 20%;
    border-radius: 0 0 40px 40px;
    transition: all 0.8s;
}

.navbar-bars {
    min-width: 20px;
    min-height: 20px;
    color: 'blue'
    // z-index: 0;
}
.navbar-content {
    width: 80%;
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr;
    flex-direction: row; 
    transition: all 1s;
}

.navbar-content.hide-navbar-content {
    display: none ;
    transition: all 0.5s;
}

.navbar-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-item {
    @include AppStyles.body-paragraph;
    background-color: transparent;
    padding: 5px;
    display: inline-block;
    justify-content: center;
}

.navbar-item:hover {
    cursor: pointer;
    transition: ease-in-out 300ms;
    border-bottom: 2px solid rgba(182, 141, 30, 0.7);
}