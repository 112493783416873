@use '../../AppStyles';

.project_card_container {
    max-width: 100%;
    max-height: 100%;
    
    margin: 0;
    /* set min-height later */

}


.project_card {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 0;
    border-radius: 5px;
}


.project_image {
    width: 100%;
   height: calc(40vw * 3 / 4);
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   z-index: 0;
}

/* .project_image {
    z-index: 0;
    max-height: 45%
} */
.project_title_holder {
    position: absolute;
    left: 50%;
    top: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    margin: 0;
    z-index: 3;
}

.background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgb(33, 33, 33, 0.5) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    z-index: 1;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
}

.background:hover {
    cursor: pointer;
    background-position: left bottom;
}

.background {
    animation: fadeOut ease 0.3s;
}

.project_card > h2 {
    font-size: 30px;
    animation: fadeOut ease 0.3s;
    visibility: hidden;
}

.project_card:hover > h2 {
    visibility: visible;
    animation: fadeIn ease 1s; 
}

@keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes fadeOut {
0% {
    opacity:1;
    visibility: visible;
}
100% {
    opacity:0;
    visibility: hidden;
}
}

@media screen and (max-width: AppStyles.$small-screen-size) {
    .project_image {
       width: 100%;
       min-height: 200px;
       height: calc(80vw * 3 / 4);

       max-height: calc(80vw * 3 / 4);
       background-size: cover;
       background-repeat: no-repeat;
       background-position: center;
       z-index: 0;
    }

}