@use '../../AppStyles';

#landing-container {
    z-index: 1;
    position: relative;
    top: -70px;
    width: 100%;
    min-height: max(750px, 100vh);
    margin: auto;
    padding: 0;
    background-color: #362419;
}

.landing-parallax {
    width: 100%;
    height: 100%;
}

.parallax-background-image {
    opacity: 0.6;
}

.landing-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: max(750px, 100vh);
    padding-top: 70px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(33, 33, 33, 0.8), 10%, rgb(33, 33, 33));
    color: white;
}

.intro-links-container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100%;
}

.intro-block {
    width: 60%;
    position: relative;
    left: 10vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.profile-picture{ 
    border: solid 2px AppStyles.$gold-color;
    border-radius: 50%;
    margin-top: 8vh;
    height: auto; 
    width: auto; 
    max-width: 250px; 
    max-height: 250px;
    z-index: 0;
}

.salutation {
    color : AppStyles.$gold-color;
    font-family: 'Lekton';
    font-size: AppStyles.$reg-mid-header-size;
    font-weight: bolder;
    margin: 40px 0 30px 0;
}

.name {
    font-size: AppStyles.$reg-huge-header-size;
    margin: -30px 0 -10px 0 ;
    z-index: 1;
}

.intro {
    width: 100%;
    overflow-wrap: break-word;
    margin: 15px 0 30px;
}

.social-links{
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(450px + 15vw);
}

.social-links::after {
    content: '';
    width: 1.5px;
    min-height: 30%;
    background: rgb(200, 200, 200);
    display: block;
    position: relative;
    left: 50%;
    transform: rotate(180deg), ;
}

.social-link-button-container {
    display: flex;
    flex-direction: row;
    min-height: 65px;
    min-width: 70px;
    z-index: 1;
    margin: 15px 0;
    justify-content: center;
}

.social-link-button-container > p {
    animation: fadeOut ease 1s;
    visibility: hidden;
}

.social-link-button-container:hover > p {
    visibility: visible;
    animation: fadeIn ease 1s; 
}

@keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes fadeOut {
0% {
    opacity:1;
    visibility: visible;
}
100% {
    opacity:0;
    visibility: hidden;
}
}


.social-link-button {
    background-color: transparent;
    color: white;
    border: solid 1.5px rgb(200, 200, 200);
    border-radius: 50%;
    box-shadow: 0 0 10px #3a3a3a;
    height: 65px;
    width: 65px;
    background-color: rgba(33, 33, 33, 0.9);
}

.social-link-button:hover {
    cursor: pointer;
}

.social-link-description {
    position: absolute;
    margin-left : 150px
}

.social-link-description.chrome {
    margin-left: 150px;
}

.fa-btn {
    min-width: 30px;
    min-height: 30px;
    z-index: 0;
}

.fa-btn.file {
    color: rgba(234, 218, 79, 1.0);
}

.fa-btn.in {
    color: rgba(85, 193, 260, 1.0);
}

.fa-btn.git {
    color: rgba(186, 97, 245, 1.0);
}

.fa-btn.mail {
    color: rgba(254, 105, 125, 1.0);
}

.slide-button-container {
    position: relative;
    width: 40px;
    height: 15vh;
    max-height: 80px;
    min-height: 7vh;
    border: solid 1.5px white;
    box-shadow: 0 0 10px #3a3a3a;
    border-radius: 35px;
    background-color: transparent;
    padding: 0;
}


.slide-button-icon {
    position: absolute;
    top: 12%;
    left: calc((40px - 6px) / 2);
    height: 16px;
    width: 6px;
    border-radius: 12px;
    box-shadow: 0 0 10px #3a3a3a;
    background-color: white;
}

.animated {
    animation: arrowslide 3s ease-in-out infinite;
}


@keyframes arrowslide {
    0% {
    transform: translate(0, 0);  
    visibility: visible;
    opacity: 1;
    transition: opacity 3s linear;
    }
    100% {
    transform: translate(0, calc(6vh));
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 3s, opacity 3s linear;
    }

    50% {
    transform: translate(0, calc(6vh));
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 3s, opacity 3s linear;
    
    }
}

#slide-button-link {
    align-self: center;
}
#slide-button-link:hover{
    cursor: pointer;
}

@media screen and (max-width: AppStyles.$small-screen-size) {
    #slide-button-link  {
        visibility: hidden;
    }
   
    .animated {
        animation: unset;
    }

    #landing-container {
        min-height: unset;
    }
    
    .landing-content {
        min-height: unset;
    }

    .salutation {
        font-size: AppStyles.$small-mid-header-size;
    }

    .name {
        font-size: AppStyles.$small-huge-header-size;
    }

    .profile-picture {
        max-width: 200px;
        max-height: 200px;
    }

    .social-links {
        width: calc(250px + 15vw);
        margin-top: 12vh;
    }

    .social-link-button-container {
        min-height: 40px;
        min-width: 40px;
    }

    .social-link-button {
        height: 40px;
        width: 40px;
    }

    .fa-btn {
        min-width: 22px;
        min-height: 22px;
    }

  
.slide-button-container {
    position: relative;
    width: 35px;
    height: 12vh;
    max-height: 60px;
    min-height: 6vh;
    border: solid 1.5px white;
    box-shadow: 0 0 10px #3a3a3a;
    border-radius: 35px;
    background-color: transparent;
    padding: 0;
}


.slide-button-icon {
    position: absolute;
    top: 12%;
    left: calc((35px - 4px) / 2);
    height: 12px;
    width: 4px;
    border-radius: 12px;
    box-shadow: 0 0 10px #3a3a3a;
    background-color: white;
}

}