@use '../../AppStyles';

#bio-container{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.bio-content{
    margin-top: 50px;
    display: block;
    color: white;
    width: 100%; 
}

.bio-header {
    display: flex;
    flex-direction: row;
}

.bio-picture {
    float: right;
    margin: -40px 30px 0 10px;
    border: solid 2px AppStyles.$gold-color;
    border-radius: 50%;
    height: auto; 
    width: auto; 
    min-width: 200px; 
    max-height: 200px;
    z-index: 0;
}

.technologies {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-direction: row;
    width: 70%;
    padding: 0 0 30px 30px;
}

.technology-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.technology-icon {
    width: AppStyles.$reg-icon-size;
    height: auto;
    margin-right: 10px;
}

.resized {
    width: 110px; 
    height: 40px;
}

.technology-name {
    padding: 0;
    font-weight: 800;
}

@media screen and (max-width: 800px) {
    .bio_content {
        width: 90%;
        line-height: 25px;
        padding: 20px;
    }

    .technologies {
        grid-template-columns: 1fr 1fr 1fr;
        min-width: 80%;
    }

    .technology-icon {
        width: AppStyles.$small-icon-size;
        height: AppStyles.$small-icon-size;
    }

    .resized {
        width: 85px;
        height: 30px;
    }

    .technologies {
        width: 100%;
        padding: 0 30px 0 10px;
        // margin: 0 20px 0 10px;
    }

    .bio-picture {
        min-width: 100px; 
        min-height: 100px;
        max-width: 120px; 
        max-height: 120px;
    }
}

@media screen and (max-width: 400px) {
    .technologies {
        grid-template-columns: 1fr 1fr;
        min-width: 80%;
    }
}