@use '../../AppStyles';

#projects {
    width: 100%;
}

.project_container {
    padding-top: 60px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 0;
    padding-bottom: 60px;
    
}

.project_container.active {
    pointer-events: all;
}

.project_content {
    max-width: 80vw;
    min-width: 800px;
}

.project-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(300px, calc(40vw * 3 / 4));
    background-color: white;
    gap: 20px;
    z-index: 0;
    margin-top: 40px;
    box-shadow: 0px 0px 5px 3px #e2e2e2;
    border-radius: 5px;
    padding: 20px;
}

@media screen and (max-width: AppStyles.$small-screen-size) {
    .project_content {
        min-width: unset;
    }

    .project-gallery {
        grid-template-columns: 1fr;
        grid-template-rows: minmax(250px, calc(80vw * 3 / 4));
    }
}