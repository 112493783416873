@use '../../AppStyles';

#experience_container {
    width: 80%;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.experience_block_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;      
}

@media screen and (max-width: AppStyles.$small-screen-size) {
    #experience_container {
        min-width: unset;
    }
}